import pointInPolygon from 'point-in-polygon';

export const NZoneColours: { [key: string]: number[] } = {
  'West Midlands': [201, 108, 8],
  'Black Country': [0, 0, 0],
  Coventry: [1, 111, 68],
  Walsall: [0, 139, 209],
  'Sandwell and Dudley': [116, 19, 114],
};
export const NZoneAreas = {
  type: 'FeatureCollection',
  name: 'NZone_Bus_Areas',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      type: 'Feature',
      properties: { objectid: 1, area_name: 'West Midlands' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-2.200745558920406, 52.633486212510761],
            [-2.206182559720153, 52.631305460966225],
            [-2.205908419343038, 52.62627748060023],
            [-2.198495839672201, 52.620652187200591],
            [-2.183094876794558, 52.619991391996606],
            [-2.175941509723657, 52.615583301671521],
            [-2.171539485127735, 52.610256520343704],
            [-2.179903489185539, 52.60498698675336],
            [-2.188432651487296, 52.609468861780996],
            [-2.205558997818254, 52.605173658988079],
            [-2.212160995870522, 52.598838243413709],
            [-2.213249669660712, 52.591141123110688],
            [-2.207345178381143, 52.585970724349558],
            [-2.202682866707177, 52.578664570529988],
            [-2.19029097704999, 52.576399479196652],
            [-2.188155384649325, 52.574193385706195],
            [-2.183658766706803, 52.57549569723178],
            [-2.178496797193601, 52.567046498557403],
            [-2.181369484819817, 52.5662802180358],
            [-2.179485873217732, 52.56430214813571],
            [-2.176857511629104, 52.563620402200506],
            [-2.176220144705556, 52.560573741604443],
            [-2.17531855611101, 52.553946520923326],
            [-2.172051873645599, 52.549684663410922],
            [-2.17801761731854, 52.541371053811332],
            [-2.191521919485305, 52.543254674146219],
            [-2.193746836696759, 52.537689129844871],
            [-2.199619957754642, 52.537755535791888],
            [-2.21445069002533, 52.529805695123926],
            [-2.213439412594867, 52.527369420059927],
            [-2.19805464207988, 52.523129553720651],
            [-2.203026125186788, 52.517711558558169],
            [-2.206244522911351, 52.511534522065745],
            [-2.202096258467642, 52.505598796472313],
            [-2.187085986593257, 52.499299686440764],
            [-2.189312456846196, 52.494496062700719],
            [-2.179886037657767, 52.47881541630089],
            [-2.178350153860614, 52.469293887656292],
            [-2.211275236310864, 52.466877696840889],
            [-2.242035151176164, 52.457066155842632],
            [-2.244868262879446, 52.450660239766606],
            [-2.224754307437633, 52.4435382645648],
            [-2.217273589838644, 52.443628465777749],
            [-2.215928518694699, 52.449040528611022],
            [-2.203230118704514, 52.453634559634864],
            [-2.201135673533149, 52.459276297039096],
            [-2.174197056964044, 52.460157164942373],
            [-2.176169741272007, 52.454363725598746],
            [-2.171377361716081, 52.440656367676041],
            [-2.164977354947792, 52.429541545799005],
            [-2.143774122812093, 52.425454933150618],
            [-2.134427874628333, 52.425923090641803],
            [-2.104432666458619, 52.443858478915686],
            [-2.093336695040624, 52.444325078303628],
            [-2.088336422026091, 52.437928855743245],
            [-2.092069933750297, 52.434802068980133],
            [-2.087701402920957, 52.432062536947555],
            [-2.081343117609507, 52.431533799987157],
            [-2.068132926847104, 52.4334470565991],
            [-2.064758347436097, 52.427505942305999],
            [-2.059897095754241, 52.427356135254485],
            [-2.058292695975722, 52.439319174556005],
            [-2.038843856854591, 52.439860576845881],
            [-2.037960347367442, 52.427593862246773],
            [-2.029733645320918, 52.427062901740328],
            [-2.02450260921861, 52.433616696172642],
            [-2.016399327787268, 52.432094290458174],
            [-2.014902119202552, 52.428361052046711],
            [-2.010165319060536, 52.426609152667695],
            [-2.005429190892351, 52.42668568945615],
            [-2.002188133846731, 52.421123777609729],
            [-1.997203410414327, 52.416628519613383],
            [-1.989602700806369, 52.414799634591972],
            [-1.995957438228046, 52.412818890014442],
            [-1.998324693286255, 52.410304549085097],
            [-2.004305125021015, 52.411142471111752],
            [-2.005301605822162, 52.409770933949986],
            [-2.007793277900504, 52.409389766031794],
            [-2.008914851505144, 52.410456366171054],
            [-2.011780196731075, 52.409770300710655],
            [-2.012651567996005, 52.407712952063847],
            [-2.017509398961481, 52.405883546090216],
            [-2.027228042102676, 52.407786446881815],
            [-2.032830646727263, 52.403213350827805],
            [-2.032455489585114, 52.401461003501062],
            [-2.024357656672774, 52.400091529817423],
            [-2.032826001835465, 52.397575027541187],
            [-2.033941582489706, 52.391098261881218],
            [-2.028958051197126, 52.388356650638009],
            [-2.015385272611433, 52.391178461666691],
            [-2.012892542263737, 52.38538812807068],
            [-2.008908031955296, 52.385693385670095],
            [-2.007039726227699, 52.383026826211072],
            [-2.006541290636588, 52.379979241999422],
            [-1.996083187035744, 52.378760660378596],
            [-1.988364096302128, 52.379522345795834],
            [-1.986121719877203, 52.383712664190433],
            [-1.983755030921853, 52.386531548635467],
            [-1.975038530781558, 52.386530239346421],
            [-1.970928260502026, 52.388205619341647],
            [-1.967690408321659, 52.388433417847509],
            [-1.963953037634601, 52.390337113882971],
            [-1.95934825100856, 52.386983143315369],
            [-1.952369706895812, 52.391933000837788],
            [-1.933696413324605, 52.386819037114435],
            [-1.917861698748778, 52.398009332708348],
            [-1.912242545108253, 52.405319640515181],
            [-1.906891563492825, 52.402496439853799],
            [-1.897548556083706, 52.402641044232553],
            [-1.889576674576905, 52.402405259876957],
            [-1.891109480526638, 52.386711125888681],
            [-1.894865201865847, 52.378180985170843],
            [-1.89985983074793, 52.371632776135826],
            [-1.89143204784284, 52.356386895328143],
            [-1.8840899502683, 52.356456175081263],
            [-1.881696820781021, 52.367349263837994],
            [-1.876464031050726, 52.369172577540851],
            [-1.868382778800046, 52.365887701061155],
            [-1.809017294790922, 52.364740826502526],
            [-1.799960955224622, 52.358173401971349],
            [-1.785691585556521, 52.349462488017316],
            [-1.776622630591316, 52.346702802407847],
            [-1.770509518342016, 52.349967402172084],
            [-1.769112434273367, 52.355450555638143],
            [-1.776791283982808, 52.362703550893293],
            [-1.763480349641586, 52.361534856119391],
            [-1.761258145024616, 52.358177931981778],
            [-1.749832732588661, 52.3539637813575],
            [-1.741369925817264, 52.354174200071036],
            [-1.732194512278426, 52.369392328384201],
            [-1.719990056902982, 52.370278434954713],
            [-1.721058539556955, 52.358699699824214],
            [-1.719340811184701, 52.354809820785022],
            [-1.715469660049336, 52.356933984501858],
            [-1.709515876673289, 52.353948028444904],
            [-1.698065455443989, 52.35430037071378],
            [-1.692991019013272, 52.350325309134384],
            [-1.683867001615948, 52.356091964838768],
            [-1.683568781253884, 52.363024691572889],
            [-1.672494254255697, 52.362689826444225],
            [-1.669123985805967, 52.364013828860465],
            [-1.663899513664724, 52.363618158510249],
            [-1.661027063060924, 52.36490524401534],
            [-1.65138587807762, 52.35634370012658],
            [-1.647839665003118, 52.356295113424324],
            [-1.636949387500873, 52.364110060416294],
            [-1.622125956290938, 52.365549442865962],
            [-1.607842266523825, 52.376246047009211],
            [-1.607416342357413, 52.382187608213933],
            [-1.603064608557543, 52.381487386235172],
            [-1.596429839578076, 52.38542701741585],
            [-1.566714613285204, 52.380750656487152],
            [-1.558700334513021, 52.37279701342537],
            [-1.558892000614393, 52.366016571260651],
            [-1.550583090944703, 52.363013828798202],
            [-1.540840361213093, 52.366405195846369],
            [-1.543550386415562, 52.369158592531214],
            [-1.535642769927581, 52.37545185528252],
            [-1.524940928686288, 52.375028507711122],
            [-1.513955008378775, 52.366450402150434],
            [-1.505933569071214, 52.371445967128246],
            [-1.489359103564521, 52.362232496717063],
            [-1.468067381656869, 52.363053135665766],
            [-1.457585097525655, 52.375501420415347],
            [-1.460529291729904, 52.379095958097039],
            [-1.446520927363968, 52.384212554458806],
            [-1.430680851720443, 52.39594757542509],
            [-1.428887203391981, 52.399824848749041],
            [-1.435495998326386, 52.409076074208279],
            [-1.421321853553094, 52.435446548433745],
            [-1.443171359668781, 52.442713151107711],
            [-1.441361279242816, 52.447809452763195],
            [-1.459062699629622, 52.458482035847013],
            [-1.493098076121647, 52.459851146312516],
            [-1.495782498137903, 52.454148117706403],
            [-1.502783306328258, 52.45265377649622],
            [-1.514113512110005, 52.4543008482148],
            [-1.514825788291807, 52.457580037917531],
            [-1.528474645549026, 52.464187582918626],
            [-1.535981146688531, 52.462083803253947],
            [-1.539947216839016, 52.46453737378436],
            [-1.551866880413451, 52.45772572404131],
            [-1.563356448220729, 52.456244712208928],
            [-1.593608091555319, 52.462371255604324],
            [-1.597511637103156, 52.458346398340765],
            [-1.59504010871434, 52.455823672835471],
            [-1.599540997973055, 52.454619872765882],
            [-1.608482128659542, 52.45899260731364],
            [-1.618535880634103, 52.464739687046915],
            [-1.626912997315651, 52.462480552586712],
            [-1.625341017262024, 52.4566088580833],
            [-1.640787974044721, 52.443551628844659],
            [-1.652278118985352, 52.441147689894954],
            [-1.660608796830541, 52.444143252189761],
            [-1.665349769605781, 52.443775742865569],
            [-1.666653484299929, 52.436236456626673],
            [-1.676127388996569, 52.436338848697233],
            [-1.676116788809644, 52.437786456829002],
            [-1.683575101576629, 52.440854203271911],
            [-1.685405234779191, 52.446497231408323],
            [-1.697547461274131, 52.45788144692893],
            [-1.70871586001459, 52.466519087524745],
            [-1.710039029374163, 52.473989162194741],
            [-1.714246193348081, 52.479408970457357],
            [-1.73102078512275, 52.491181576741297],
            [-1.742527488601227, 52.508578717193267],
            [-1.753489411538418, 52.513325745519637],
            [-1.753324912060369, 52.520411231119098],
            [-1.746834424379917, 52.519635728896169],
            [-1.727943883729273, 52.524546594331945],
            [-1.740016940057686, 52.531964092787675],
            [-1.748139064770552, 52.531752935787594],
            [-1.746833167825087, 52.541578862239852],
            [-1.752815115088221, 52.544486669028963],
            [-1.756249901300331, 52.556150958341242],
            [-1.764373141826282, 52.556548217872859],
            [-1.767116435766778, 52.557848840963921],
            [-1.766222412422802, 52.561428063921277],
            [-1.773455819001927, 52.564870622340287],
            [-1.763049903735536, 52.570183747950189],
            [-1.769025897051261, 52.575147871094714],
            [-1.777029882471789, 52.575086946397157],
            [-1.783500922902694, 52.581575114841975],
            [-1.796051614153599, 52.59927357512197],
            [-1.828422886909032, 52.608696133284411],
            [-1.845581016450929, 52.605291071041883],
            [-1.853974275705253, 52.602711199599604],
            [-1.856613807657413, 52.599133475144491],
            [-1.860104548577278, 52.60317568502456],
            [-1.873490231097546, 52.604638229566326],
            [-1.880127150047636, 52.603045092136071],
            [-1.88800854842256, 52.603967015997441],
            [-1.883482559114849, 52.611886412257739],
            [-1.891717748907492, 52.622332119737607],
            [-1.909725140984517, 52.632327892849965],
            [-1.914979866016211, 52.634693619786326],
            [-1.906076472920826, 52.640325004822436],
            [-1.905068529284097, 52.643067042867379],
            [-1.917455789893188, 52.6497809161474],
            [-1.915949137498461, 52.651532233568695],
            [-1.908177634914945, 52.653583654393877],
            [-1.912679233343037, 52.657853642084241],
            [-1.923833805922242, 52.655956512880621],
            [-1.932093945718848, 52.661675691592109],
            [-1.934976202626927, 52.661524909184706],
            [-1.940621382482941, 52.656804113928246],
            [-1.944129336308143, 52.656958190006733],
            [-1.952893110691258, 52.663057119299232],
            [-1.959660787206668, 52.661992957027671],
            [-1.956034814314447, 52.654220367408435],
            [-1.960044732662428, 52.653307513291033],
            [-1.96118099721704, 52.643555669427798],
            [-1.96293447205409, 52.643632421026332],
            [-1.96418876484743, 52.641499508698864],
            [-1.974083230987473, 52.641578283982106],
            [-1.974960888306384, 52.639978501155944],
            [-1.981097912214845, 52.639674888714637],
            [-1.982349454519114, 52.641808380484925],
            [-1.98385320310936, 52.639751488226274],
            [-1.986483021583645, 52.640589900495712],
            [-1.989990657711681, 52.637237925844516],
            [-1.993872757922224, 52.638457221221721],
            [-2.003891711883611, 52.63708591778817],
            [-2.009277050684315, 52.638380837935117],
            [-2.01378526956594, 52.63723756385842],
            [-2.013533496406351, 52.632894806701024],
            [-2.019166800923852, 52.628932186427477],
            [-2.024298438772335, 52.625274151755221],
            [-2.029181491476858, 52.625653993206818],
            [-2.036189887728369, 52.62214733415172],
            [-2.031931320681633, 52.619253370954837],
            [-2.031053549543667, 52.617120300117058],
            [-2.046830247704081, 52.621381645733543],
            [-2.049834159698444, 52.620847076097931],
            [-2.052089040218439, 52.622141310130544],
            [-2.063855241054205, 52.620992611832754],
            [-2.064104177823384, 52.620002013496155],
            [-2.068857838635958, 52.617866001488274],
            [-2.066847153329124, 52.612686285575101],
            [-2.070478096242966, 52.613446046030241],
            [-2.08224262735262, 52.612905038661324],
            [-2.084874588259856, 52.614731709001347],
            [-2.086258058572129, 52.618083037966571],
            [-2.08801397654707, 52.619757903645088],
            [-2.092772850348288, 52.620592325805973],
            [-2.096785516546916, 52.623560481202766],
            [-2.098056455687578, 52.631940272112431],
            [-2.099812209298921, 52.633081637039766],
            [-2.101194151223369, 52.63498519217346],
            [-2.110962989284147, 52.635281143263342],
            [-2.113725076313438, 52.637945134570856],
            [-2.13150844065064, 52.637698013936102],
            [-2.136620273578119, 52.630378050696287],
            [-2.141629723524807, 52.630600690650759],
            [-2.143255308890698, 52.629913019832735],
            [-2.152270075027935, 52.629673132295771],
            [-2.157281924317767, 52.630580815515742],
            [-2.173426652634824, 52.62850107643834],
            [-2.188217870198237, 52.632287839581771],
            [-2.200745558920406, 52.633486212510761],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { objectid: 2, area_name: 'Coventry' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-1.650235084619519, 52.439351191291635],
            [-1.649263668088973, 52.436072089727872],
            [-1.642673689138008, 52.433919077734778],
            [-1.632950369279276, 52.433889418592351],
            [-1.621609164892628, 52.433549052008985],
            [-1.611400110675054, 52.432068439260625],
            [-1.603391953026154, 52.407660620839202],
            [-1.602053567594333, 52.390131849556099],
            [-1.608341782317521, 52.38314306729368],
            [-1.60312720321277, 52.381449499313156],
            [-1.597110899462724, 52.385848390509793],
            [-1.579453686986811, 52.38319659175361],
            [-1.567270813776925, 52.381171751857252],
            [-1.558139032246217, 52.372909210015237],
            [-1.557956640532542, 52.366203568916092],
            [-1.550261551378125, 52.364041216761606],
            [-1.535448479022807, 52.376174920562718],
            [-1.524989742762263, 52.376285876551677],
            [-1.514381872973267, 52.367252182608141],
            [-1.506109697081541, 52.372399111176698],
            [-1.489044029498585, 52.362574011890246],
            [-1.46862330364786, 52.363398505592428],
            [-1.458020337753773, 52.375541509019719],
            [-1.461459788492396, 52.379366864546959],
            [-1.447953304926069, 52.384181147604281],
            [-1.430361174175848, 52.396593689099838],
            [-1.429568841547731, 52.400094821320572],
            [-1.436178777051242, 52.409269804036164],
            [-1.423450796057059, 52.429933059208608],
            [-1.417685133260814, 52.432342983366034],
            [-1.41812952951041, 52.436459492684278],
            [-1.426855775802377, 52.436502029462368],
            [-1.443236099673357, 52.44252297669528],
            [-1.44142699899603, 52.447543093470493],
            [-1.457805968471982, 52.4541714949385],
            [-1.460490039387216, 52.45905996860904],
            [-1.493412072769617, 52.459662003824079],
            [-1.494964489611353, 52.454792283035218],
            [-1.497976588399575, 52.453128804481445],
            [-1.504968557865845, 52.452396258067211],
            [-1.515172257075103, 52.45441947627107],
            [-1.514887727099486, 52.457618388242032],
            [-1.529286638488213, 52.464076519034123],
            [-1.536292576936581, 52.462123115815615],
            [-1.540508147264489, 52.464577642919465],
            [-1.551309879291896, 52.45730456622114],
            [-1.563044274856533, 52.456281659839114],
            [-1.593672207058043, 52.462180995389481],
            [-1.597451698163352, 52.458079524774966],
            [-1.595224749594812, 52.456090972937773],
            [-1.596865594861361, 52.440248680837236],
            [-1.617558997036289, 52.440621908103125],
            [-1.644743350942083, 52.440096808096435],
            [-1.650235084619519, 52.439351191291635],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { objectid: 3, area_name: 'Sandwell and Dudley' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-2.081886774461997, 52.431714383891475],
            [-2.078644817947773, 52.432716795954995],
            [-2.077773574770688, 52.433924965510847],
            [-2.074856860934027, 52.433900826996123],
            [-2.074229606602481, 52.432854767560428],
            [-2.073102152331048, 52.433770178838671],
            [-2.070671240808308, 52.433784918207444],
            [-2.067729083795083, 52.435074610788668],
            [-2.067715106635017, 52.432562788460416],
            [-2.062544739616464, 52.432378834743069],
            [-2.063612750900371, 52.430850817665835],
            [-2.065557575207882, 52.430492285753729],
            [-2.066710327417511, 52.429655330603488],
            [-2.065713786526724, 52.428031004552288],
            [-2.060905833518781, 52.427505723139767],
            [-2.059357297857405, 52.428779001401516],
            [-2.058648586521367, 52.434120363368379],
            [-2.059944634725244, 52.436471865249715],
            [-2.059451251363171, 52.439815633583464],
            [-2.05549201966289, 52.441236274491082],
            [-2.05149449377836, 52.440825830164108],
            [-2.049021627252412, 52.439529778138223],
            [-2.046445880794206, 52.439855266996886],
            [-2.044726965804744, 52.440078134178407],
            [-2.044366519923797, 52.44071208485326],
            [-2.037949677916722, 52.441562771409735],
            [-2.037189582057936, 52.439562940791241],
            [-2.039906304502992, 52.437022590118836],
            [-2.03705492637861, 52.427575295460585],
            [-2.029995229223112, 52.427216169437877],
            [-2.029317966672325, 52.429233491569711],
            [-2.025928290851571, 52.430123909365861],
            [-2.025933423385106, 52.431937600618078],
            [-2.024394670817948, 52.435010469286766],
            [-2.017243007964355, 52.43275823779598],
            [-2.016606766429122, 52.44644671119547],
            [-2.013308734192547, 52.448282140889198],
            [-2.011697556355812, 52.452684951464114],
            [-2.01133554200547, 52.457990641666839],
            [-2.012132590355618, 52.462304028431333],
            [-2.013144738710609, 52.467138074170059],
            [-2.014861931776649, 52.471436211456606],
            [-2.0185772534585, 52.475485397413088],
            [-2.021759521421696, 52.480713193022503],
            [-2.023613617655025, 52.484848819719637],
            [-2.021325233530583, 52.48882701161731],
            [-2.01613041832859, 52.495329648956215],
            [-2.015160483007299, 52.496532382350793],
            [-2.012212115271815, 52.498293620611904],
            [-2.00760518845537, 52.501821407472242],
            [-1.998693651308209, 52.504988182578728],
            [-1.991549453209273, 52.505781829338162],
            [-1.982441975316854, 52.505558025466911],
            [-1.978463329782376, 52.506590712720595],
            [-1.976142553764168, 52.507963489063485],
            [-1.974795651332657, 52.510673607281511],
            [-1.971111152243922, 52.516423802619713],
            [-1.969918446199237, 52.521184144815969],
            [-1.969420991225269, 52.525303149910044],
            [-1.969618648436415, 52.53268255610007],
            [-1.968398790044631, 52.536907434600231],
            [-1.967190571719941, 52.540240599161336],
            [-1.96636475008678, 52.545877546314394],
            [-1.969714515277208, 52.551019574964151],
            [-1.974001253434942, 52.555896094304281],
            [-1.977233149556375, 52.558132484034488],
            [-1.985000165750068, 52.562386149060927],
            [-1.99059177009928, 52.564127957587502],
            [-2.002764638645197, 52.567523332963482],
            [-2.00888565148356, 52.569368185254334],
            [-2.011844858439686, 52.572321247487828],
            [-2.013295051553539, 52.575874359327116],
            [-2.015228692298155, 52.575943825525968],
            [-2.017051470323461, 52.575613894388141],
            [-2.018481916434643, 52.575075935448126],
            [-2.020120552756186, 52.573328448380508],
            [-2.020342757574643, 52.572050117197747],
            [-2.021769807577398, 52.57112867933585],
            [-2.023825943049412, 52.570158823363258],
            [-2.030143768200461, 52.568506288073038],
            [-2.032576155027696, 52.567400816259557],
            [-2.035171778381334, 52.566983448806006],
            [-2.036776461999426, 52.568576187723565],
            [-2.038373338131377, 52.569186101778534],
            [-2.038418273165628, 52.569883439685434],
            [-2.039695781895341, 52.570315744599448],
            [-2.0473190710447, 52.570744206966431],
            [-2.040842707748803, 52.56532343518397],
            [-2.044499975483891, 52.56353746253501],
            [-2.050944295872265, 52.561020751552739],
            [-2.057744698503418, 52.561185179212679],
            [-2.063423165411682, 52.562549826160186],
            [-2.072953274274004, 52.566239519520678],
            [-2.083178961644593, 52.562971460780787],
            [-2.084485445832146, 52.561429993716914],
            [-2.087192437428501, 52.559479163651496],
            [-2.087839818352783, 52.558064537602029],
            [-2.089808796793192, 52.555061211397565],
            [-2.096623079408233, 52.552915579730282],
            [-2.099355685441501, 52.554813669764037],
            [-2.10141425516479, 52.55509965304428],
            [-2.101357616028539, 52.554089861919969],
            [-2.098182624731608, 52.550378872897731],
            [-2.098775797022132, 52.548528029293443],
            [-2.098214766310261, 52.546552165235397],
            [-2.105212144905478, 52.546223819208052],
            [-2.107479438763406, 52.544957179753617],
            [-2.109151643649964, 52.5446418220249],
            [-2.113236069089223, 52.544330620141238],
            [-2.116923535752548, 52.543769294440636],
            [-2.121728668186267, 52.543123836719587],
            [-2.122703598589754, 52.542216879533292],
            [-2.125452919639707, 52.54017643267467],
            [-2.128465164836425, 52.538814014114628],
            [-2.130242730761785, 52.538189520017283],
            [-2.132968361158933, 52.536374210573207],
            [-2.136935134837155, 52.533999509688158],
            [-2.141554869626825, 52.531729705101746],
            [-2.145910295501709, 52.529399415747335],
            [-2.145802499636031, 52.52866665801524],
            [-2.15174663629154, 52.526271394924073],
            [-2.152030755519229, 52.525563915458115],
            [-2.147955600848165, 52.523672965321744],
            [-2.145805535474837, 52.522451287401424],
            [-2.144529506577125, 52.520268054137688],
            [-2.14512553486284, 52.517690289250211],
            [-2.143981831127472, 52.517208063422729],
            [-2.150400030727265, 52.514045182710149],
            [-2.152470524749087, 52.51510217660897],
            [-2.157118525511619, 52.515194322160724],
            [-2.165058738826013, 52.514470388516798],
            [-2.174271511376202, 52.512403619391662],
            [-2.178041928975475, 52.511734013752928],
            [-2.180196716965973, 52.510786928817161],
            [-2.183063350296298, 52.509617383786996],
            [-2.185897357512266, 52.508887812985215],
            [-2.188105289044382, 52.508335855761835],
            [-2.18720557672821, 52.507492014523585],
            [-2.187961726545383, 52.50716655677688],
            [-2.18744371767024, 52.505906791681781],
            [-2.1876911070599, 52.50503868246787],
            [-2.189875913742402, 52.504813679697961],
            [-2.19215955779489, 52.503553041224713],
            [-2.18454710227182, 52.500025089150128],
            [-2.18842767869292, 52.494432213166171],
            [-2.185098533795139, 52.494872875722109],
            [-2.18417328995166, 52.492804576548671],
            [-2.182279103313077, 52.493123495372188],
            [-2.177659796853769, 52.485059164386605],
            [-2.178691595540574, 52.48230138504821],
            [-2.177517261889342, 52.478728651209956],
            [-2.176126006429306, 52.477118139835262],
            [-2.172089819267188, 52.472590094363476],
            [-2.169157923843146, 52.472339890376482],
            [-2.167592284394611, 52.471266406207803],
            [-2.171603966600229, 52.465858407835633],
            [-2.174430588312026, 52.463677294412989],
            [-2.173899843789788, 52.460996630184354],
            [-2.176454045122453, 52.45471201510766],
            [-2.17528069773049, 52.453101898527116],
            [-2.174463732913818, 52.4498063304898],
            [-2.172255197758306, 52.442701256964739],
            [-2.16898357393181, 52.436501339520319],
            [-2.164694349596742, 52.430177115942634],
            [-2.152269592434055, 52.427704894133534],
            [-2.147309208523097, 52.4265209666776],
            [-2.143837207090704, 52.426166144572413],
            [-2.138188676094827, 52.426140429696673],
            [-2.13594485805857, 52.426270056918455],
            [-2.135461385339889, 52.428036693786055],
            [-2.133207741685119, 52.427554018145749],
            [-2.13236297448727, 52.429910724346662],
            [-2.130412206847567, 52.4296114459206],
            [-2.129352539318135, 52.42978132155848],
            [-2.129487498526063, 52.432059814550691],
            [-2.124393826272303, 52.433261856774251],
            [-2.11956633709293, 52.434648012685294],
            [-2.117009342181725, 52.43447354388902],
            [-2.11167073750019, 52.437297640261484],
            [-2.106758144418546, 52.442354095560937],
            [-2.105052391518765, 52.444255218671998],
            [-2.101094311363584, 52.444253424539355],
            [-2.098310185430914, 52.445147168791699],
            [-2.092800385666672, 52.445010807967137],
            [-2.094014162089337, 52.443842321681579],
            [-2.090217073335621, 52.44259196457849],
            [-2.090482403081261, 52.441262788553345],
            [-2.089112468206034, 52.440679123866914],
            [-2.087137549837605, 52.436925677534809],
            [-2.087397167916199, 52.434691344318409],
            [-2.085823563448086, 52.432699639465987],
            [-2.083875001565507, 52.431998295987107],
            [-2.082621957967179, 52.432299284079114],
            [-2.081886774461997, 52.431714383891475],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { objectid: 4, area_name: 'Black Country' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-2.242463780711936, 52.456489036651085],
            [-2.245426624332759, 52.450997117364402],
            [-2.238683416374257, 52.449334748107631],
            [-2.225686384040531, 52.443874552296386],
            [-2.217707189039581, 52.444041939961942],
            [-2.21573973658767, 52.44968374035949],
            [-2.20304102908442, 52.454277783478389],
            [-2.201317902477265, 52.459309365719292],
            [-2.17438145714252, 52.460875959744847],
            [-2.176230765912015, 52.45538747298739],
            [-2.175599011911644, 52.453331253178035],
            [-2.17181152031594, 52.441451008217385],
            [-2.164288408200242, 52.430033011716318],
            [-2.144705372921833, 52.426020490051378],
            [-2.13573188651081, 52.426183521085306],
            [-2.118553282559877, 52.434354447357371],
            [-2.104614423003716, 52.444196509992601],
            [-2.093892837101748, 52.444815355564096],
            [-2.087645032571496, 52.438039146286499],
            [-2.091876731574969, 52.434683311411],
            [-2.087633212494392, 52.432096086588302],
            [-2.081899019236784, 52.432024111589797],
            [-2.068439969943712, 52.434394839363208],
            [-2.065314012014813, 52.428148728233928],
            [-2.060451846679801, 52.427465590758985],
            [-2.058349555563987, 52.439886250849369],
            [-2.038401646529115, 52.440656444489377],
            [-2.037642163859001, 52.427779926078784],
            [-2.02966451244255, 52.427096498684584],
            [-2.024184818129792, 52.434564803924424],
            [-2.016829251967654, 52.432966071888551],
            [-2.016461399680177, 52.446604840673459],
            [-2.013469406333443, 52.44828147176419],
            [-2.011227444402512, 52.457500950569624],
            [-2.014599583596677, 52.471062682254491],
            [-2.02371479270754, 52.484851887545233],
            [-2.021158358968666, 52.489004804552252],
            [-2.015169963105718, 52.496624872825166],
            [-2.00749349595304, 52.501844724010901],
            [-1.998629046880391, 52.504968809456663],
            [-1.991387049757019, 52.505844709939403],
            [-1.982522009048616, 52.505500878976093],
            [-1.978338602922034, 52.506719247641037],
            [-1.976152838115215, 52.507937888574688],
            [-1.971027828410236, 52.516546359686018],
            [-1.969274051884211, 52.525269876522934],
            [-1.969644408882542, 52.53273671714075],
            [-1.968768040513389, 52.535593607150695],
            [-1.967018027408332, 52.536735978650299],
            [-1.964517601139685, 52.53867809087285],
            [-1.960829261661203, 52.541267377717091],
            [-1.952517319921643, 52.543054846346209],
            [-1.934330973978771, 52.545370075095171],
            [-1.93202026770069, 52.544530690882105],
            [-1.93058269578273, 52.544758422838456],
            [-1.931268751500725, 52.545558807662218],
            [-1.919582943317469, 52.545818104762873],
            [-1.917768926708617, 52.546769220113141],
            [-1.912323508338265, 52.550993761255008],
            [-1.909449286034542, 52.550686812101823],
            [-1.907197174461679, 52.55171360162214],
            [-1.906881701737327, 52.55308475852582],
            [-1.899503028855074, 52.554640534787708],
            [-1.896492882777816, 52.558790226170288],
            [-1.891609028713704, 52.562061988180886],
            [-1.878460748387333, 52.569554065220963],
            [-1.875740850493504, 52.580770576388012],
            [-1.87285422421779, 52.584196172491879],
            [-1.871537944969057, 52.585185252881764],
            [-1.870094045248943, 52.587012288529166],
            [-1.868403353470684, 52.58765806731158],
            [-1.864015618671008, 52.590700815904135],
            [-1.859809987414654, 52.59530552646774],
            [-1.856050384707305, 52.597167745747214],
            [-1.861223448565661, 52.60338339595576],
            [-1.873357815714915, 52.604882523865221],
            [-1.876924867251892, 52.604733851978182],
            [-1.881183968436384, 52.603176238318305],
            [-1.889191589045858, 52.603717213624783],
            [-1.886868300112305, 52.606915044401816],
            [-1.886614585440317, 52.608248134518021],
            [-1.884856183742117, 52.610646456008951],
            [-1.886032957311129, 52.615409460329495],
            [-1.892713471865492, 52.622158423951937],
            [-1.898027547842796, 52.62505825813848],
            [-1.904720190318964, 52.627692306677723],
            [-1.905845041061049, 52.628645572145011],
            [-1.909594990087879, 52.631772249292709],
            [-1.91541080817461, 52.635357428122106],
            [-1.904812333443995, 52.642778135539132],
            [-1.90906673420349, 52.644762383844338],
            [-1.918201014358516, 52.649797516145739],
            [-1.916003967294761, 52.652234122040483],
            [-1.908608648214967, 52.654019245420173],
            [-1.912546202729306, 52.658403140783491],
            [-1.923639765274683, 52.655743986444023],
            [-1.933716173390307, 52.661807093511186],
            [-1.935722050813105, 52.661160563249879],
            [-1.94048997106971, 52.656591612876788],
            [-1.944687063781634, 52.656707920602237],
            [-1.953764037899388, 52.662807007304693],
            [-1.960093010634572, 52.66185692626992],
            [-1.955151957876483, 52.653779003298908],
            [-1.960539101829595, 52.653780923602078],
            [-1.960856448200088, 52.649285817687421],
            [-1.9599795979727, 52.649247428024687],
            [-1.960925125343308, 52.642695412326077],
            [-1.962928299962204, 52.643648437098015],
            [-1.964496249259757, 52.640906089124599],
            [-1.975517671032909, 52.641175586818648],
            [-1.976144829220948, 52.639537637709715],
            [-1.981780470983523, 52.640033895516908],
            [-1.982969776317282, 52.641253114550338],
            [-1.984285354998369, 52.639958074521296],
            [-1.986977935009379, 52.640377455729599],
            [-1.990047233372056, 52.637063507185708],
            [-1.99443021065784, 52.638549513156072],
            [-2.00463701628589, 52.637025796472706],
            [-2.009646615794681, 52.638244547810515],
            [-2.013716546043363, 52.63725369317644],
            [-2.014153644616689, 52.63317748878729],
            [-2.015968378418482, 52.630243952214421],
            [-2.018910246270796, 52.628605443276463],
            [-2.019661774580197, 52.6292529354821],
            [-2.024229871053265, 52.62529021624713],
            [-2.029738868032509, 52.625631819276208],
            [-2.035995901009255, 52.621896765742612],
            [-2.031800171464369, 52.619193231220478],
            [-2.031423282541656, 52.617250484535056],
            [-2.047324999289815, 52.621511716446868],
            [-2.050578695751902, 52.620481775290763],
            [-2.052082756438586, 52.622081092955156],
            [-2.06384889469785, 52.621008502990215],
            [-2.064409920348027, 52.619408203128643],
            [-2.068413880332707, 52.618224954885214],
            [-2.06740412251744, 52.612701772910512],
            [-2.071598187854351, 52.613423056837405],
            [-2.081108517179947, 52.612273939855335],
            [-2.084302342125377, 52.613452610014704],
            [-2.088007688995427, 52.619887933486595],
            [-2.092828103036967, 52.620188962836586],
            [-2.097595044098514, 52.624489826024885],
            [-2.09836373015155, 52.632108202376799],
            [-2.100307325534492, 52.633211311077289],
            [-2.101125548654921, 52.635001075018351],
            [-2.106135036630102, 52.635034738259357],
            [-2.111521320868762, 52.635486876758172],
            [-2.113718769190915, 52.637694274956431],
            [-2.131752962076908, 52.637446765092591],
            [-2.136989809605699, 52.630088496383593],
            [-2.142062951463987, 52.630615767964336],
            [-2.143000225299821, 52.630043205405443],
            [-2.153016260947864, 52.629611504843538],
            [-2.15777842749209, 52.630709938374487],
            [-2.173734992390993, 52.628516054367864],
            [-2.187962893126841, 52.632303606778414],
            [-2.200804616041808, 52.633691886893359],
            [-2.206052913219474, 52.631320968127213],
            [-2.205403278230546, 52.626331745472378],
            [-2.199181192113805, 52.62097109552645],
            [-2.182778500528062, 52.620312019459867],
            [-2.176062697664533, 52.615788988396332],
            [-2.171159884387952, 52.610462927674781],
            [-2.180273947511595, 52.604963646899549],
            [-2.187862832620669, 52.609027952106167],
            [-2.196925135823463, 52.606460775357874],
            [-2.205615081164089, 52.604769741470989],
            [-2.212847113008954, 52.599347409468415],
            [-2.212807090095278, 52.591118955226115],
            [-2.207092460952072, 52.586443422078233],
            [-2.206835392652169, 52.584996253579732],
            [-2.202832872722179, 52.585079397326346],
            [-2.202138315922172, 52.583671062729223],
            [-2.203890062277081, 52.583782333777272],
            [-2.201802731032378, 52.578757335715721],
            [-2.189475236566957, 52.576987295130067],
            [-2.187588534621618, 52.574514109415496],
            [-2.183093890931932, 52.57631165599345],
            [-2.178053044470004, 52.566947901048678],
            [-2.181615841630671, 52.566751990443755],
            [-2.179541191323648, 52.563936103473196],
            [-2.176353703605215, 52.56413139098747],
            [-2.17558858875407, 52.56039917592436],
            [-2.176399224870063, 52.559902724523567],
            [-2.175875963885376, 52.554112989845422],
            [-2.174313141920818, 52.554039120968007],
            [-2.171545274187328, 52.549471721237566],
            [-2.174342424177413, 52.545658068381371],
            [-2.178262066102412, 52.54142359475501],
            [-2.192015855494534, 52.543154377531273],
            [-2.193678618968402, 52.537627794535872],
            [-2.19942513732987, 52.537313450215663],
            [-2.214383892357813, 52.529972833146992],
            [-2.213185762810685, 52.527651162240936],
            [-2.208151713307604, 52.52644108953811],
            [-2.197581338660374, 52.523240212339864],
            [-2.203301298057834, 52.51759232013076],
            [-2.206364614115406, 52.511625027203202],
            [-2.202090017058911, 52.50538471741649],
            [-2.187331581142948, 52.499618537315278],
            [-2.189056362963608, 52.494282295544544],
            [-2.180003059081136, 52.478296207937078],
            [-2.177969742609285, 52.469156382466828],
            [-2.211145926656872, 52.466968444969723],
            [-2.242463780711936, 52.456489036651085],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { objectid: 5, area_name: 'Walsall' },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-1.995595307320034, 52.638295650311655],
            [-1.998359387893527, 52.637995298991555],
            [-2.001034856109149, 52.637418416728337],
            [-2.003597858405752, 52.637248070234492],
            [-2.004835165451039, 52.636726006349058],
            [-2.005272242930476, 52.63637607947755],
            [-2.00659892140078, 52.6352808843722],
            [-2.008397820012391, 52.634527512721334],
            [-2.009472167349226, 52.634302274925687],
            [-2.009974813941203, 52.63465825470324],
            [-2.0125863917023, 52.633461417827789],
            [-2.014359609938444, 52.633191525946209],
            [-2.01552802873158, 52.631262135700609],
            [-2.01598431558415, 52.630314417511599],
            [-2.016512371441258, 52.630056317494933],
            [-2.01788998105719, 52.629225328677109],
            [-2.018872652068458, 52.628558235774001],
            [-2.019458843646992, 52.629133065945304],
            [-2.019973551719015, 52.628284119736364],
            [-2.020653840515104, 52.627759428721738],
            [-2.021301288173259, 52.627368094447128],
            [-2.022627360667443, 52.626373512511734],
            [-2.02423798862299, 52.625167797716877],
            [-2.025213920979345, 52.625008556453558],
            [-2.026013976086572, 52.625128218766889],
            [-2.026619989534718, 52.625303855047022],
            [-2.026526719438671, 52.625602444430555],
            [-2.026704140570775, 52.625611362770975],
            [-2.027145980550168, 52.625447795977109],
            [-2.028311188450962, 52.625546224976326],
            [-2.029030930621574, 52.625519714622335],
            [-2.029293065742629, 52.625700180343472],
            [-2.029503995742626, 52.625680276563436],
            [-2.029414623653112, 52.625477999611093],
            [-2.029864352059358, 52.625388336668209],
            [-2.030398605938252, 52.625126755287162],
            [-2.030788489781546, 52.624822353881711],
            [-2.030879253633247, 52.624521899858692],
            [-2.030610780921611, 52.624001173154774],
            [-2.035502425070001, 52.621771843373622],
            [-2.031833935122921, 52.619281071490569],
            [-2.032060522462367, 52.618658514055497],
            [-2.030190779867643, 52.616917543722998],
            [-2.031304727596043, 52.616968729311367],
            [-2.031655647921193, 52.617319436400194],
            [-2.032685687539939, 52.617660776937782],
            [-2.037685775481393, 52.618921470502123],
            [-2.041367305558647, 52.619885839876368],
            [-2.044766631787882, 52.620688478163743],
            [-2.047475621225566, 52.62139321726022],
            [-2.048079757292227, 52.620625913798122],
            [-2.044601716911287, 52.616024033724784],
            [-2.042941114006217, 52.614817547665574],
            [-2.041358960819378, 52.613812410041078],
            [-2.040881166007887, 52.612233250588723],
            [-2.0410786755989, 52.610293192494119],
            [-2.041243412620452, 52.607592049456407],
            [-2.041662861606161, 52.60659296147071],
            [-2.043311628564011, 52.604386541429541],
            [-2.045295543579551, 52.60260273114087],
            [-2.046729542466478, 52.601613890875171],
            [-2.047579765533289, 52.600280473476445],
            [-2.048021792533978, 52.599382382839323],
            [-2.048152307320562, 52.597658216767719],
            [-2.049315880139423, 52.596950539962471],
            [-2.051234924276609, 52.595932802184521],
            [-2.052395974468154, 52.595232750850506],
            [-2.052863178902538, 52.593220880130325],
            [-2.053730230972245, 52.592054454069881],
            [-2.055277422501228, 52.591205358440611],
            [-2.056679334184164, 52.590266342851052],
            [-2.056962293808581, 52.589556522229522],
            [-2.05702098329941, 52.588602697694789],
            [-2.057311215444581, 52.587283992102947],
            [-2.059526559227306, 52.586853770245227],
            [-2.062281511622214, 52.585983543667659],
            [-2.06318205506765, 52.585271076357103],
            [-2.063246345284002, 52.584292517438037],
            [-2.062803723908932, 52.58299653883649],
            [-2.059807191988139, 52.583025035758922],
            [-2.054858250653166, 52.582916734903449],
            [-2.054970682248688, 52.580463768882531],
            [-2.054959761319583, 52.578276816467238],
            [-2.056954500669858, 52.577608153883631],
            [-2.058649364331924, 52.575997910707258],
            [-2.060581294341146, 52.574739943017327],
            [-2.061991315678597, 52.573724398282238],
            [-2.062959021107754, 52.572994061743387],
            [-2.065705235270348, 52.571298382073579],
            [-2.069313340645773, 52.570212171370088],
            [-2.072097075957283, 52.568822204932594],
            [-2.07373040198508, 52.568029390245705],
            [-2.075300210967426, 52.567331471412331],
            [-2.075097814893738, 52.5669082522327],
            [-2.074093338235096, 52.566235273042771],
            [-2.075635546278788, 52.565937602488859],
            [-2.076282933001954, 52.565623099210868],
            [-2.075702083062158, 52.56529689246188],
            [-2.074959787264054, 52.564629772905683],
            [-2.071057239167604, 52.564622912532073],
            [-2.070031592696544, 52.56408651408664],
            [-2.068461428128135, 52.563445102225863],
            [-2.066109241957689, 52.562393875093065],
            [-2.063753158114515, 52.561588220587417],
            [-2.062342050300082, 52.561103515663106],
            [-2.05907128916426, 52.560305955238093],
            [-2.056483488798921, 52.560185082421633],
            [-2.052158921142211, 52.560136814945743],
            [-2.050236156310566, 52.560047312515088],
            [-2.048468331070558, 52.560305510461973],
            [-2.047508178748101, 52.560809193331785],
            [-2.045550281843165, 52.56139700019105],
            [-2.043511857947169, 52.56225486277355],
            [-2.041581211133097, 52.563409677544662],
            [-2.040490359851668, 52.56392557562107],
            [-2.039683807788004, 52.562667378350234],
            [-2.039294799479173, 52.561479458938194],
            [-2.038800908669218, 52.560972015026351],
            [-2.038367771104126, 52.560523279269198],
            [-2.03813473566372, 52.559979446219451],
            [-2.038119947231507, 52.559455655024784],
            [-2.038131396252907, 52.558414915346354],
            [-2.038308134624711, 52.557549442531396],
            [-2.037304351051442, 52.557205245227784],
            [-2.034420360518409, 52.55831521126872],
            [-2.031681645852345, 52.559589117626999],
            [-2.029493206360648, 52.560289931680728],
            [-2.028225208572306, 52.558722654658467],
            [-2.026301120563164, 52.556625715433036],
            [-2.024900495912967, 52.555794321382265],
            [-2.023770666235102, 52.555350521300817],
            [-2.023237858311585, 52.554637757426541],
            [-2.022715724484104, 52.553689841719887],
            [-2.023025542911904, 52.552908971300951],
            [-2.023481690486888, 52.552609988144269],
            [-2.024311112162578, 52.552266572178183],
            [-2.025024473607569, 52.552095125503136],
            [-2.025089535654648, 52.551883800119697],
            [-2.024577591667875, 52.5516727624914],
            [-2.024008148856775, 52.551502992111018],
            [-2.02355328841979, 52.551523249629845],
            [-2.022986265297115, 52.551785586587293],
            [-2.022874794740996, 52.552145948647905],
            [-2.022243633970792, 52.552909505550645],
            [-2.021253922606518, 52.553605231451925],
            [-2.018841781872874, 52.55323408670975],
            [-2.016708317925805, 52.553273037405305],
            [-2.014694966058679, 52.553473809173347],
            [-2.012847393855119, 52.553171646044412],
            [-2.011526251330758, 52.55272539714764],
            [-2.010493787252403, 52.551754412850045],
            [-2.009554753573733, 52.550977753598254],
            [-2.008636896936537, 52.55136747593415],
            [-2.007617826326308, 52.551955876942543],
            [-2.006760686497159, 52.552554228262984],
            [-2.005326684394416, 52.552752524031945],
            [-2.005525172433748, 52.554180273916259],
            [-2.004416697768431, 52.554602473676653],
            [-2.002908951544855, 52.555240202519812],
            [-2.002764686515865, 52.555981201739002],
            [-2.001509163173143, 52.555825499749389],
            [-2.001549605394863, 52.556393199268847],
            [-2.001542830247439, 52.558724543972524],
            [-1.999890646562482, 52.558521833054606],
            [-1.996932773001231, 52.558299380724399],
            [-1.997731910924106, 52.556201825215112],
            [-1.997762437602199, 52.554593830773968],
            [-1.997486817391536, 52.553431783412911],
            [-1.995885709384566, 52.553151498731857],
            [-1.993946239589257, 52.552847948950088],
            [-1.992097170201188, 52.552317848987535],
            [-1.993286707392181, 52.550525820323486],
            [-1.993961753507324, 52.549368607441991],
            [-1.992687427549161, 52.547714400329383],
            [-1.991278487418488, 52.546147037677066],
            [-1.987310706372793, 52.545581364061185],
            [-1.984577621932364, 52.545032513199864],
            [-1.985481186312531, 52.542273534366061],
            [-1.985842022410422, 52.540431885159279],
            [-1.987007432561229, 52.538357764442367],
            [-1.987680840493757, 52.536423968708867],
            [-1.98797953575226, 52.534868158503521],
            [-1.986166900028872, 52.534304799289174],
            [-1.989565062394323, 52.53024145548941],
            [-1.991544189409136, 52.52733485962132],
            [-1.992844476542078, 52.525388671180288],
            [-1.993941895299975, 52.523982526896084],
            [-1.994147742644547, 52.523354539643336],
            [-1.99400333759341, 52.522547032099943],
            [-1.993577951526854, 52.521789866571112],
            [-1.99420639743623, 52.521042408125176],
            [-1.993032469530692, 52.519653497168534],
            [-1.994543019476834, 52.518820850088744],
            [-1.994902894558744, 52.518639943294517],
            [-1.995622442127261, 52.518233728725377],
            [-1.996339096303346, 52.517848039224361],
            [-1.995751484146085, 52.517475088537026],
            [-1.99448856330639, 52.516782358453014],
            [-1.994088521953512, 52.516578430523843],
            [-1.993640244404423, 52.516356972878754],
            [-1.992796335940671, 52.515933219326989],
            [-1.992105019411475, 52.515487678840891],
            [-1.991433117763877, 52.515255837178508],
            [-1.991124972664687, 52.515237204234218],
            [-1.990937384419583, 52.515337733932476],
            [-1.990752738841854, 52.515487046559628],
            [-1.990578352559446, 52.515732911628348],
            [-1.990456878668892, 52.516118061691422],
            [-1.990253104537826, 52.516447008978048],
            [-1.989836457325521, 52.516997741690304],
            [-1.989158342698393, 52.517723427251184],
            [-1.988527803545096, 52.518740819789755],
            [-1.987511192954418, 52.52041813820567],
            [-1.985635179429533, 52.522221486244611],
            [-1.986585304154685, 52.523949228984904],
            [-1.986276910379204, 52.528744671165391],
            [-1.98623012202197, 52.530721360648378],
            [-1.985447655412735, 52.533325584765905],
            [-1.984619560982708, 52.534450687458317],
            [-1.978706536502641, 52.534024234946656],
            [-1.973551454680526, 52.534382522561017],
            [-1.969095349189657, 52.53548059862073],
            [-1.967200722401671, 52.536608624969261],
            [-1.964662014683303, 52.538650753055023],
            [-1.960883543892225, 52.541350826987042],
            [-1.957943354999495, 52.542267318981942],
            [-1.954745400150666, 52.542592970993539],
            [-1.952723748036623, 52.543032568376859],
            [-1.950659452640865, 52.543099267133037],
            [-1.948556835475024, 52.543352030198648],
            [-1.94492254619513, 52.543932007112879],
            [-1.943507595347642, 52.54418440313902],
            [-1.943184348885154, 52.544347002532632],
            [-1.94250617698281, 52.544531521847262],
            [-1.94149186150233, 52.544682791959374],
            [-1.940297821224697, 52.544925683259798],
            [-1.93768906932468, 52.545188315852201],
            [-1.934645203084509, 52.545429838503487],
            [-1.933572199687275, 52.545150979052117],
            [-1.932933174566155, 52.545022121734085],
            [-1.932184851372399, 52.544661580562853],
            [-1.931816538615125, 52.544432915789315],
            [-1.930576689570826, 52.54477505676337],
            [-1.931583933163389, 52.545773165798401],
            [-1.930922951018319, 52.545751019191911],
            [-1.929082783676684, 52.545659054978451],
            [-1.921654311664383, 52.545939014003352],
            [-1.918713606298834, 52.546414546869521],
            [-1.917519445987811, 52.547223190775114],
            [-1.914932021399924, 52.549099161697278],
            [-1.913311985876632, 52.55026146667025],
            [-1.912556457572705, 52.551084921277031],
            [-1.91103249004883, 52.551103246650271],
            [-1.909799973416334, 52.550704180696151],
            [-1.908279608862464, 52.551148653021784],
            [-1.907453276852081, 52.551749854035073],
            [-1.907161651481331, 52.553239214150075],
            [-1.904068797798693, 52.553861207948913],
            [-1.901511792604165, 52.554261842376931],
            [-1.900265701935656, 52.554552178763963],
            [-1.899474065834563, 52.554947378729921],
            [-1.898821395725568, 52.555513740524745],
            [-1.898421349692859, 52.55594365358138],
            [-1.898049217969237, 52.556285261696786],
            [-1.897696582781598, 52.556905419993328],
            [-1.897025560411319, 52.55818527254759],
            [-1.896365809759081, 52.558898979777261],
            [-1.895523459566281, 52.55950009958913],
            [-1.894717119761292, 52.56008407743596],
            [-1.894069474202963, 52.560473263902779],
            [-1.89320847391643, 52.56100173576187],
            [-1.891504769133281, 52.56218139320513],
            [-1.888635937836509, 52.56348105444625],
            [-1.886187758522172, 52.564977286110306],
            [-1.885525985490278, 52.565347029210201],
            [-1.884442420798315, 52.566076036488475],
            [-1.882086691620891, 52.567638480437125],
            [-1.881293410781864, 52.568037543061209],
            [-1.880583616920883, 52.56835654235983],
            [-1.87983511798516, 52.568726673073762],
            [-1.87900013152016, 52.569170350055039],
            [-1.878399298561835, 52.569599661646983],
            [-1.878297766820446, 52.570428221239851],
            [-1.878020023459763, 52.571504407588385],
            [-1.877936676891148, 52.572126824914939],
            [-1.877779227127616, 52.572743360254499],
            [-1.8775730837281, 52.573381573209709],
            [-1.877469217851923, 52.574042801368861],
            [-1.877319716047817, 52.574701513711631],
            [-1.877155718804857, 52.575619350321965],
            [-1.876813694971767, 52.576998467295056],
            [-1.876549877263838, 52.577692403397201],
            [-1.876285422019411, 52.578904538458282],
            [-1.876068890381945, 52.579928578960953],
            [-1.875460678570851, 52.581181823231674],
            [-1.874450779740991, 52.582444747805809],
            [-1.872528384851633, 52.584912408510249],
            [-1.876183298103141, 52.586409067500945],
            [-1.87784839672484, 52.587484131594799],
            [-1.879316448140427, 52.588719595569025],
            [-1.880771332930946, 52.590156710211744],
            [-1.882382939763455, 52.592388815245279],
            [-1.884917620148084, 52.594869923039397],
            [-1.888425209766979, 52.597187485613269],
            [-1.89065658597132, 52.597980550264403],
            [-1.890241562950588, 52.598438800757677],
            [-1.892259673709484, 52.59914286234536],
            [-1.892372105307086, 52.599716278672211],
            [-1.892117078592938, 52.60034830403351],
            [-1.891778622522942, 52.600798752755338],
            [-1.891465515884843, 52.601238705371102],
            [-1.891048963687966, 52.601686469490829],
            [-1.890692684443513, 52.602057378207668],
            [-1.890232404306198, 52.602514506399238],
            [-1.88989981204462, 52.602912395876601],
            [-1.889386739437727, 52.603413190615271],
            [-1.889203373123796, 52.603783292700975],
            [-1.889009572419743, 52.60443036723332],
            [-1.888230103278721, 52.605394895639073],
            [-1.886964825388232, 52.606863392894148],
            [-1.886728680488879, 52.608052804522565],
            [-1.884980853859095, 52.610491221867541],
            [-1.885293778035031, 52.613634642751101],
            [-1.886160372250362, 52.615517566603053],
            [-1.893701223033085, 52.622132668012277],
            [-1.893844826231812, 52.622413969097103],
            [-1.894138630817746, 52.622695269943378],
            [-1.900761113705592, 52.626170656424925],
            [-1.906683771972213, 52.629140459018366],
            [-1.909968263477273, 52.631803600714335],
            [-1.914606573796135, 52.634234968699438],
            [-1.915989806519919, 52.635298398710042],
            [-1.914119644349966, 52.636118014192341],
            [-1.913764442880932, 52.635879887364851],
            [-1.912960754676723, 52.636208079520038],
            [-1.913265099293081, 52.636440127131351],
            [-1.91273351291538, 52.636658691114818],
            [-1.913138649656285, 52.637082939907479],
            [-1.911230153726849, 52.638269687359255],
            [-1.910846656693191, 52.638182894783007],
            [-1.907992470138852, 52.640017645921311],
            [-1.907509549697803, 52.639823516331226],
            [-1.907561057958456, 52.640576504676822],
            [-1.905968149098018, 52.641775373000897],
            [-1.906404805988439, 52.642165537693948],
            [-1.905381642339522, 52.642836434839893],
            [-1.905204000488211, 52.643233070619857],
            [-1.907882298992447, 52.644149305536402],
            [-1.909080828489611, 52.644758585544039],
            [-1.909766689472419, 52.644365283927925],
            [-1.912366451253868, 52.646500287178029],
            [-1.915671172101126, 52.648229731683429],
            [-1.917966682148281, 52.649893900423542],
            [-1.915856851795319, 52.651448317826627],
            [-1.91652170306236, 52.652281144466201],
            [-1.908630289637312, 52.654053159044835],
            [-1.912770708678261, 52.658666519634217],
            [-1.915342534633122, 52.658126137081901],
            [-1.919566378189707, 52.657204952726993],
            [-1.921760173052879, 52.656603308845106],
            [-1.923502966179731, 52.655752412443043],
            [-1.926296681708737, 52.656926104591371],
            [-1.933985970936833, 52.661974345601067],
            [-1.938592835941886, 52.657474978112816],
            [-1.940408852294279, 52.65702585735206],
            [-1.941310240406863, 52.656849949199767],
            [-1.94572235328536, 52.656910109047992],
            [-1.953732860352177, 52.662777417874004],
            [-1.960025221210003, 52.662023990490539],
            [-1.956264805930458, 52.656238650937695],
            [-1.955423082493371, 52.65354046225378],
            [-1.960706738192432, 52.653584775229398],
            [-1.960931916436002, 52.649325275360233],
            [-1.960044128144151, 52.649308627959407],
            [-1.959978520747548, 52.645707237553687],
            [-1.961104038943878, 52.642766075310618],
            [-1.962964023685122, 52.643764421948504],
            [-1.964485080892035, 52.640805660064238],
            [-1.967426418057109, 52.640908606501434],
            [-1.967732163922526, 52.641558434573454],
            [-1.971457180955502, 52.64146015699982],
            [-1.973524987433946, 52.640645768754879],
            [-1.975251356098992, 52.641337091055043],
            [-1.976216030206996, 52.639763877296204],
            [-1.978703240771974, 52.640055095972087],
            [-1.981602081821153, 52.639914983255501],
            [-1.982165706328203, 52.640867999806773],
            [-1.982882133328792, 52.641281622818568],
            [-1.984895622108742, 52.63977877583504],
            [-1.986914575803952, 52.640427349204366],
            [-1.988773841727504, 52.639153825349112],
            [-1.989661150581979, 52.637959713417445],
            [-1.990801754746758, 52.636633564783807],
            [-1.993123813995599, 52.638240809754151],
            [-1.995595307320034, 52.638295650311655],
          ],
        ],
      },
    },
  ],
};

export const getBusAreas = (coordinates: [number, number]) => {
  const busAreaPolygons = NZoneAreas.features.map((area) => ({
    name: area.properties.area_name,
    polygon: area.geometry.coordinates[0],
  }));

  const stopBusAreas: string[] = [];
  busAreaPolygons.forEach((poly: any) => {
    const isWithinArea = pointInPolygon(coordinates, poly.polygon);
    if (isWithinArea) {
      stopBusAreas.push(poly.name);
    }
  });

  return stopBusAreas || null;
};
